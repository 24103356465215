<template>
  <div>
    <channel-management-method-view
      :method-options="methodOptions"
      @updated="refetchElements"
    />
    <channel-management-provider-view
      class="mt-2"
      :provider-options="providerOptions"
      @updated="refetchElements"
    />
    <channel-management-region-view
      class="mt-2"
      :region-options="regionOptions"
      @updated="refetchElements"
    />
    <channel-management-currency-view
      class="mt-2"
      :currency-options="currencyOptions"
      @updated="refetchElements"
    />
  </div>
</template>

<script>
import ChannelManagementMethodView from './ChannelManagementMethodView.vue'
import ChannelManagementProviderView from './ChannelManagementProviderView.vue'
import ChannelManagementRegionView from './ChannelManagementRegionView.vue'
import ChannelManagementCurrencyView from './ChannelManagementCurrencyView.vue'
import useChannelsJs from './channels'

const {
  fetchChannelElements,
} = useChannelsJs()

export default {
  components: {
    ChannelManagementMethodView,
    ChannelManagementProviderView,
    ChannelManagementRegionView,
    ChannelManagementCurrencyView,
  },
  data() {
    return {
      methodOptions: [],
      providerOptions: [],
      regionOptions: [],
      currencyOptions: [],
    }
  },
  created() {
    this.refetchElements()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    refetchElements() {
      fetchChannelElements(
        elements => {
          this.methodOptions = elements.methods
          this.providerOptions = elements.providers
          this.regionOptions = elements.regions
          this.currencyOptions = elements.currencies
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
