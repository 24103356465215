<template>
  <div>
    <b-card
      title="Channel Methods"
      class="mb-0"
    >
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="methodOptions"
        show-empty
        hover
        outlined
        selectable
        select-mode="single"
        sticky-header
        :borderless="false"
        empty-text="No matching records found"
        @row-selected="onRowSelected"
      >
        <template #cell(is_default)="data">
          {{ data.item.is_default ? 'Yes' : 'No' }}
        </template>
      </b-table>

      <b-button
        v-b-modal.modal-method-add-new
        variant="success"
        class="float-right mr-2"
      >
        <feather-icon
          icon="PlusIcon"
        />
        Add New Method
      </b-button>
      <b-button
        variant="danger"
        class="float-right mr-2"
        @click.prevent="deleteConfirm"
      >
        <feather-icon
          icon="Trash2Icon"
        />
        Delete
      </b-button>
    </b-card>

    <b-modal
      id="modal-method-add-new"
      cancel-variant="outline-secondary"
      ok-title="Accept"
      cancel-title="Close"
      centered
      title="Add New Method"
      @ok="addNew"
    >
      <b-form>
        <b-form-group>
          <label for="type_id">Type ID</label>
          <b-form-input
            id="type_id"
            v-model="type.type_id"
            type="number"
            oninput="if(value.length > 4)value = value.slice(0, 4)"
          />
        </b-form-group>
        <b-form-group>
          <label for="type_name">Type Name</label>
          <b-form-input
            id="type_name"
            v-model="type.type_name"
            oninput="if(value.length > 32)value = value.slice(0, 32)"
          />
        </b-form-group>
        <b-form-group>
          <label for="description">Description</label>
          <b-form-input
            id="description"
            v-model="type.type_desc"
            oninput="if(value.length > 32)value = value.slice(0, 32)"
          />
        </b-form-group>
        <b-form-checkbox
          v-model="type.is_default"
          name="check-button"
        >
          Set As Default
        </b-form-checkbox>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useChannelsJs from './channels'

const {
  saveChannelElement,
} = useChannelsJs()

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    methodOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      type: {
        type_id: '',
        type_name: '',
        type_desc: '',
        is_default: false,
      },
      tableFields: [
        { key: 'type_id', label: 'ID' },
        { key: 'type_name', label: 'Name' },
        { key: 'type_desc', label: 'Description' },
        { key: 'is_default', label: 'Default' },
      ],
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    showToast(title, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant: 'warning',
          },
        },
        {
          position: 'top-center',
        },
      )
    },
    deleteConfirm() {
      if (this.selected < 1) {
        this.showToast('Warning', 'No Method Selected.')
        return
      }
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete()
        }
      })
    },
    delete() {
      const type = this.selected[0]
      const item = {
        index: 1,
        op: 'DELETE',
        data: type,
      }
      saveChannelElement(
        item,
        () => {
          this.showAlert('success', 'Success!', `${type.type_desc} ${item.op} Done.`)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    addNew() {
      const item = {
        index: 1,
        op: 'CREATE',
        data: this.type,
      }
      saveChannelElement(
        item,
        () => {
          this.showAlert('success', 'Success!', `${this.type.type_desc} ${item.op} Done.`)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
