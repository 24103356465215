<template>
  <div>
    <b-card
      title="Channel Providers"
      class="mb-0"
    >
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="providerOptions"
        show-empty
        hover
        outlined
        selectable
        select-mode="single"
        sticky-header
        :borderless="false"
        empty-text="No matching records found"
        @row-selected="onRowSelected"
      >
        <template #cell(provider_type)="data">
          {{ resolveProviderType(data.item.provider_type) }}
        </template>
      </b-table>

      <b-button
        v-b-modal.modal-provider-add-new
        variant="success"
        class="float-right mr-2"
      >
        <feather-icon
          icon="PlusIcon"
        />
        Add New Provider
      </b-button>
      <b-button
        variant="danger"
        class="float-right mr-2"
        @click.prevent="deleteConfirm"
      >
        <feather-icon
          icon="Trash2Icon"
        />
        Delete
      </b-button>
    </b-card>

    <b-modal
      id="modal-provider-add-new"
      cancel-variant="outline-secondary"
      ok-title="Accept"
      cancel-title="Close"
      centered
      title="Add New Provider"
      @ok="addNew"
    >
      <b-form>
        <b-form-group>
          <label for="provider_id">Provider ID</label>
          <b-form-input
            id="provider_id"
            v-model="provider.provider_id"
            type="number"
            oninput="if(value.length > 4)value = value.slice(0, 4)"
          />
        </b-form-group>
        <b-form-group>
          <label for="provider_code">Provider Code(use to a parameter with API)</label>
          <b-form-input
            id="provider_code"
            v-model="provider.provider_code"
            oninput="if(value.length > 32)value = value.slice(0, 32)"
          />
        </b-form-group>
        <b-form-group>
          <label for="provider_name">Provider Name</label>
          <b-form-input
            id="provider_name"
            v-model="provider.provider_name"
            oninput="if(value.length > 32)value = value.slice(0, 32)"
          />
        </b-form-group>
        <b-form-group
          label="Provider Type"
          label-for="provider-type"
        >
          <v-select
            v-model="provider.provider_type"
            :options="providerTypeOptions"
            :clearable="false"
            :searchable="false"
            :reduce="val => val.value"
            input-id="provider-type"
          />
        </b-form-group>
        <b-form-textarea
          v-model="provider.ext"
          rows="3"
          no-resize
        />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useChannelsJs from './channels'

const {
  saveChannelElement,
} = useChannelsJs()

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    providerOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      provider: {
        provider_id: '',
        provider_code: '',
        provider_name: '',
        provider_type: '2',
        ext: '',
      },
      tableFields: [
        { key: 'provider_id', label: 'ID' },
        { key: 'provider_code', label: 'Code' },
        { key: 'provider_name', label: 'Name' },
        { key: 'provider_type', label: 'Type' },
      ],
      providerTypeOptions: [
        { label: 'Bank', value: '1' },
        { label: 'PSP', value: '2' },
      ],
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    showToast(title, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant: 'warning',
          },
        },
        {
          position: 'top-center',
        },
      )
    },
    resolveProviderType(type) {
      switch (type) {
        case '1':
          return 'Bank'
        case '2':
          return 'PSP'
        default:
          return 'Default'
      }
    },
    deleteConfirm() {
      if (this.selected < 1) {
        this.showToast('Warning', 'No Provider Selected.')
        return
      }
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete()
        }
      })
    },
    delete() {
      const provider = this.selected[0]
      const item = {
        index: 2,
        op: 'DELETE',
        data: provider,
      }
      saveChannelElement(
        item,
        () => {
          this.showAlert('success', 'Success!', `${provider.provider_name} ${item.op} Done.`)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    addNew() {
      const item = {
        index: 2,
        op: 'CREATE',
        data: this.provider,
      }
      saveChannelElement(
        item,
        () => {
          this.showAlert('success', 'Success!', `${this.provider.provider_name} ${item.op} Done.`)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
